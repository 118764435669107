import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { rhythm, scale } from "../../utils/typography";

import Img from "gatsby-image";

import "./box-list.scss";
import HeaderBig from "../header-big/header-big";
import Button from "../button/button";

interface boxItemProps {
  title: string;
  span?: string;
  description?: string;
}

interface Props {
  backgroundColor?: string;
  spanColor?: string;
  color?: string;
  boxItems: boxItemProps[];
}

class BoxList extends React.Component {
  static defaultProps = {
    backgroundColor: "#000",
    spanColor: "#484848",
    color: "#fff",
  };

  constructor(public props: Props) {
    super(props);
  }

  render() {
    if (this.props.boxItems && this.props.boxItems.length > 0) {
      return (
        <div
          className="box-list-wrapper"
          style={{ backgroundColor: this.props.backgroundColor }}
        >
          {/* <div className="container"> */}
          <div className="item-wrapper r :g :c-4 :c-6:md- :c-12:xs- :w :a-se :s">
            {this.props.boxItems.map((item, index) => {
              return (
                <div key={index} className="box-item">
                  <div
                    className="margin"
                    style={{ borderColor: this.props.color }}
                  >
                    <h3 style={{ color: this.props.color }}>
                      {item.span && (
                        <span
                          style={{
                            color: this.props.spanColor && this.props.spanColor,
                          }}
                          className="span"
                          dangerouslySetInnerHTML={{
                            __html: item.span,
                          }}
                        ></span>
                      )}
                      {item.title && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.title,
                          }}
                        ></span>
                      )}
                    </h3>
                    {item.description && (
                      <p
                        style={{ color: this.props.color }}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* </div> */}
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default BoxList;
