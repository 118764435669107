import React, { useRef } from "react";
import SEO from "../../components/seo/seo";
import { useStaticQuery, graphql } from "gatsby";
import { PageParams } from "../../../types";
import Img from "gatsby-image";

import "./www-platform.scss";

import { InView } from "react-intersection-observer";

import Button from "../../components/button/button";
import Hero from "../../components/hero/hero";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import StickyContainer from "../../components/sticky/sticky-container";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
import { Portfolio, PortfolioNode } from "../../utils/portfolio";
import Section from "../../components/air-section/air-section";
import BoxList from "../../components/box-list/box-list";
import ScrollButton from "../../components/scroll-button/scroll-button";

const PortaleInternetowePage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const experienceItems = [
    {
      name: "Spotkanie",
      to: "/",
    },
    {
      name: "Przygotowanie wstępnej wyceny i&nbsp;draftu umowy",
      to: "/",
    },
    {
      name: "Przygotowanie specyfikacji, określenie funkcjonalności",
      to: "/",
    },
    {
      name: "Projekt graficzny",
      to: "/",
    },
    {
      name: "Programowanie",
      to: "/",
    },
    {
      name: "Modułowe wydania portalu",
      to: "/",
    },
    {
      name: "Opieka powdrożeniowa",
      to: "/",
    },
  ];

  const data = useStaticQuery(
    graphql`
      {
        project: file(
          relativePath: { eq: "sklepy-internetowe/yummypix-rwd.png" }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rwd: file(relativePath: { eq: "portale-internetowe/curem_rwd_cms.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seo: file(relativePath: { eq: "mac-mock.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portfolio: allFile(
          filter: {
            sourceInstanceName: { eq: "portfolio" }
            extension: { eq: "mdx" }
          }
        ) {
          group(field: relativeDirectory) {
            nodes {
              id
              sourceInstanceName
              relativeDirectory
              relativePath
              childMdx {
                frontmatter {
                  hero_color
                  color
                  section_bg
                  category
                  slug
                  title
                  language
                  description
                  short_description
                  created
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 900, maxHeight: 600) {
                        base64
                        src
                        aspectRatio
                        sizes
                        src
                        srcSet
                      }
                    }
                  }
                  gallery_alt
                  gallery {
                    childImageSharp {
                      fluid(maxWidth: 1600) {
                        base64
                        src
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
                body
              }
            }
          }
        }
      }
    `
  );
  const itemSlugs = ["wallers", "yummypix", "zaklady-miesne-kozlowski"];
  const items = Portfolio.getNodesByLanguage("pl", data.portfolio.group)
    .filter((node: PortfolioNode) => {
      return itemSlugs.indexOf(node.childMdx.frontmatter.slug) >= 0;
    })
    .map((node: PortfolioNode) => {
      return node.childMdx.frontmatter;
    });

  return (
    <>
      <SEO
        title="Portale internetowe"
        description="Tworzymy profesjonalne portale internetowe - ogłoszeniowe, społecznościowe, katalogi, informacyjne. •Portale www ☎ +48 665 035 451"
        lang={language}
      />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Portale internetowe</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Funkcjonalne, nowoczesne i&nbsp;profesjonalne portale internetowe
              w&nbsp;technologii Web 2.0.
            </h2>
          </div>
          <h4>
            W&nbsp;naszych realizacjach, klienci docenili: kompleksowość,
            dedykowane funkcjonalności i&nbsp;rozwiązania, intuicyjność obsługi
            panelu administracyjnego oraz innowacyjność.
          </h4>
        </div>
        <ScrollButton style={{
          position: "absolute",
          left: "50%",
          bottom: "5%",
          transform: "translateX(-50%)"
        }} to={scrollRef} />
      </Hero>

      <Section className="container" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Co zyskasz"
          headerText="Dlaczego warto zaprojektować portal internetowy w&nbsp;naszej agencji"
        />
        <p>
          W&nbsp;porównaniu z&nbsp;tradycyjną stroną internetową, portale www
          wprowadzają zdecydowanie większą interaktywność z&nbsp;użytkownikiem.
          Tworzenie dedykowanych witryn internetowych wymaga bardziej
          zaawansowanych technologii. To dzięki nim, możliwe jest stworzenie
          funkcji i&nbsp;usług serwisu, dostosowanych do indywidualnych potrzeb
          klienta.
        </p>
      </Section>

      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.project.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="01 Działamy Wszechstronnie"
              headerText="Wysokie wymagania nie stanowią dla nas problemu"
            />
            <p>
              Portale społecznościowe, portale tematyczne, serwisy korporacyjne,
              platformy konkursowe oraz inne... Jesteśmy otwarci na wszystkie
              Twoje pomysły!<br></br>
              Wspólnie doprecyzujemy koncepcję, przedstawimy własne pomysły
              i&nbsp;sugestie aby dopracować funkcjonalności umożliwiające
              wyróżnienie portalu na tle konkurencji.
            </p>
            <p>
              Stworzymy każdy portal: ogłoszeniowy, ofertowy, turystyczny oparty
              na dostępie ogólnym lub abonamentowym, bez względu na jego zasięg
              oraz przewidywaną ilość użytkowników.
            </p>
            <p>
              Wystarczy oryginalny pomysł i&nbsp;kilka spotkań, które pozwolą
              przełożyć Twój pomysł na gotowy portali internetowy z
              wykorzystaniem najnowszych technologii oraz wszystkich prawideł
              będącym standardem przy tworzeniu takich rozwiązań.
            </p>
            <p>
              <strong>Warto wiedzieć, że:</strong>
            </p>
            <ul>
              <li>przeprowadzimy audyt konkurencyjności portalu,</li>
              <li>
                prowadzimy aktywny kontakt z&nbsp;klientem w&nbsp;procesie
                tworzenia portalu,
              </li>
              <li>
                zapewnimy unikalne rozwiązania, które przyciągną użytkowników,
              </li>
              <li>
                transparentnie przedstawimy zasadę funkcjonowania współpracy.
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="02</br>Wysoka jakość"
              headerText="RWD, UI, UX, niezawodność i&nbsp;łatwość obsługi"
            />
            <p>
              Wykonamy projekt graficzny, zaprogramujemy oraz zajmiemy się
              wdrożeniem gotowego produktu. Stworzymy portal internetowy
              zapewniając nieszablonowe i&nbsp;dedykowane rozwiązania, które
              uatrakcyjnią korzystanie. Dostarczymy rozwiązania spełniające
              indywidualne potrzeby. Przetłumaczymy treść na wiele języków oraz
              dostosujemy portal do wymagań WCAG 2.1.
            </p>
            <p>
              Możemy przygotować kalendarium, newsy, wyszukiwarkę galerię
              katalogi produktów newsletter, listę ogłoszeń, szybkie płatności
              system banerów reklamowych. Stawiamy na skalowalność umożliwiającą
              szybki rozwój&nbasp; kolejne funkcjonalności.
            </p>
            <p>
              <strong>Współpraca z&nbasp;naszą agencją pozwala na:</strong>
            </p>
            <ul>
              <li>szybki rozwoju i&nbsp;skalowalność portalu,</li>
              <li>kompleksową realizację w&nbsp;krótkim terminie.</li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.rwd.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.seo.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="03 Doświadczenie"
              headerText="Zespół programistów na którym można polegać"
            />
            <p>
              Na przestrzeni lat wyspecjalizowaliśmy się w&nbsp;tworzeniu
              zaawansowanych portali internetowych. Przygotowaliśmy niezawodne
              rozwiązania pozwalające tworzyć funkcjonalne oprogramowanie
              w&nbsp;rozsądnym budżecie. Posiadamy zespół doświadczonych
              developerów, sprawdzonych w&nbsp;realizacji komercyjnych projektów
              IT.
            </p>
            <p>
              Nasze doświadczenie potwierdzą rekomendacje klientów takich jak:
              BP, YES, Hilding Anders, sieć ABC oraz wielu innych...
            </p>
            <p>
              <strong>Zapewniamy:</strong>
            </p>
            <ul>
              <li>
                bezproblemową współpracę w&nbsp;trakcie tworzenia portalu
                i&nbsp;podczas jego rozwoju,
              </li>
              <li>
                wysoką jakość, popartą wieloletnim doświadczeniem
                i&nbsp;rekomendacjami klientów.
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container static">
            <HeaderBig
              tag="h2"
              span="04 Administracja"
              headerText="Pełna opieka po wdrożeniowa"
            />
            <p>
              Możemy zaproponować kompleksową opiekę nad portalem po jego
              uruchomieniu. Usługa administracji może obejmować opiekę
              techniczną, aktualizację oprogramowania oraz wprowadzanie zmian
              zgodnie z potrzebami wynikającymi z prowadzenia portalu
              internetowego.
            </p>
            <p>
              Zapewnimy regularne tworzenie kopii zapasowych (backup całego
              systemu), automatyczne sprawdzanie dostępności portalu oraz
              zabezpieczenia przed atakami hakerów.
            </p>
            <p>
              <strong>Warto pamiętać o:</strong>
            </p>
            <ul>
              <li>bezpieczeństwie i kopiach zapasowych,</li>
              <li>monitoringu dostępności usług portalu.</li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.rwd.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <div className="align-center">
          <Button color="primary" type="inversed" to="/kontakt" language="pl">
            Darmowa wycena portalu www
          </Button>
        </div>
      </Section>

      <Section className="container">
        <HeaderBig
          tag="h2"
          span="Portale internetowe"
          headerText="Kilka naszych realizacji"
        />
        <PortfolioList items={items}></PortfolioList>
        <div className="align-center">
          <Button
            color="primary"
            type="inversed"
            to="/realizacje"
            language="pl"
          >
            Zobacz wszystkie realizacje
          </Button>
        </div>
      </Section>

      <Section backgroundColor="#000" className="container">
        <HeaderBig
          tag="h2"
          span="Profity portali</br>internetowych"
          spanColor="#484848"
          headerText="Po co tworzyć profesjonalny portal internetowy"
          color="#50817b"
        />
        <BoxList
          boxItems={[
            {
              title: `Portale społecznościowe`,
              span: "01",
              description:
                "Tworzenie społeczności użytkowników, która umożliwia komunikację w&nbsp;obrębie zgromadzonej grupy, to bardzo często wykorzystywana funkcja portali. Realizowaliśmy takie zadania między innymi dla Kanału Zielone Pogotowie. Po stworzeniu aplikacji, w&nbsp;pierwsze 3&nbsp;miesiące zgromadziła ona ponad 6000 ogrodników, którzy mogli aktywnie wymieniać między sobą informacje.",
            },
            {
              title: `Portale ogłoszeniowe`,
              span: "02",
              description:
                "Informowanie użytkowników o&nbsp;nowych ofertach, rekrutacjach, produktach stały koniecznością w&nbsp;komunikacji B2B i&nbsp;B2C. Merytorycznie wspieramy naszych klientów przy planowaniu portali ogłoszeniowych. Pracowaliśmy przy tworzeniu takich portali jak Codzienny Poznań czy FurryOwl - ogłoszenia rekrutacji z&nbsp;możliwością przeprowadzania testów kandydatów.",
            },
            {
              title: `Portale korporacyjne i&nbsp;konkursowe`,
              span: "03",
              description:
                "Dla dużych klientów korporacyjnych, tworzymy specjalnie przygotowane portale internetowe z&nbsp;możliwością ograniczonego dostępu (w zależności od przydzielonych uprawnień). Portale z&nbsp;dedykowaną strefą dla: pracowników, klientów, zalogowanych, niezalogowanych, dla hurtowników. Tworzymy również platformy konkursowe i&nbsp;ankiety do przeprowadzenia badać w&nbsp;sieci.",
            },
          ]}
        />
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="container">
            <HeaderBig
              tag="h2"
              span="Przegieg współpracy"
              headerText="Etapy tworzenia portalu internetowego"
            />
            <p>
              Przed złożeniem zapytania ofertowego, postaraj się odpowiedzieć na
              pytanie "po jaką usługę przychodzisz do agencji". Zastanów się,
              jakiego zakresu kompetencji potrzebujesz, w&nbsp;jakim obszarze
              mamy wspierać Twój pomysł? Podsumuj swoje oczekiwania
              i&nbsp;przygotuj pytania.
            </p>
            <p>
              Aby ułatwić to zadanie, przygotowaliśmy etapy prawidłowo
              zaplanowanej współpracy przy tworzeniu portalu internetowego.
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                color="primary"
                type="default"
                to="/kontakt"
                language="pl"
              >
                Wyceń swój portal internetowy
              </Button>
            </div>
          </div>
          <ul className="experience count container">
            {experienceItems.map((item, index) => {
              return (
                <InView key={index} rootMargin="-49% 0px -49% 0px">
                  {({ inView, ref, entry }) => (
                    <li ref={ref} className={inView ? "active" : ""}>
                      <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                    </li>
                  )}
                </InView>
              );
            })}
          </ul>
        </StickyContainer>
      </Section>
      <Section className="container align-center">
        <Button color="primary" type="default" to="/oferta" language="pl">
          Wróć na stronę oferty
        </Button>
      </Section>
      <CallFooter />
    </>
  );
};

export default PortaleInternetowePage;
